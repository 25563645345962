import React from "react";

// Components
import SEO from "../components/seo";
import Layout from "../components/layout";

// SCSS
import {
  gutter,
  boardMember,
  boardMemberName,
  boardMemberTitle,
  boardMemberBio,
} from "../style/style.module.scss";

// ReCaptcha
import { RunReCaptcha } from "../services/recaptcha";

const FormatBoardMember = ({
  name,
  title,
  bio,
}: {
  name: string;
  title: string;
  bio: JSX.Element;
}): JSX.Element => (
  <div className={boardMember}>
    <div className={boardMemberName}>{name}</div>
    <div className={boardMemberTitle}>{title}</div>
    <div className={boardMemberBio}>{bio}</div>
  </div>
);

const BoardPage = (): JSX.Element => {
  // Render
  return (
    <Layout>
      <SEO title="Senior Management and Board of Directors: About Us" />
      <RunReCaptcha action="board" />
      <div className={gutter}>
        <h1>About Us</h1>
        <h2>Senior Management and Board of Directors</h2>

        <FormatBoardMember
          name="Shaker A. Mousa, PhD, MBA, FACC, FACB, FNAI"
          title="Founder and Executive Vice President of Drug Discovery and Drug Development"
          bio={
            <>
              <p>
                Prior to transitioning to academia as a Professor of
                Pharmacology, Executive Vice President, Vice Provost of
                Research, Dr. Mousa held a senior, principal research scientist
                and a research fellow position at DuPont Pharmaceuticals and
                Imaging Co., DuPont Merck, and DuPont Pharmaceuticals Company
                for two decades. Among Dr. Mousa’s professional accomplishments
                are his contributions to patents related to the discovery and
                development of FDA-approved products. He contributed to the
                discovery of novel anti-platelet, anti-thrombotic therapies,
                ultra-short acting cardio selective beta-adrenergic receptor
                blockers, noninvasive myocardial perfusion and thrombus imaging
                agents. His work is reported in over 1,000 peer-reviewed
                publications and he holds over 400 US and International Patents.
                In 2002, he founded state of the art Pharmaceutical Research
                Institute (PRI) focusing on translational research in Drug
                Discovery and Drug Development. The Institute utilized various
                forms of enabling technologies ranging from biotechnology,
                nanotechnology, stem cells, drug design, high throughput
                screening, formulation, drug delivery, and the study of
                pharmacokinetics, pharmacodynamics, and pharmacogenomics.
              </p>
              <p>
                Dr. Mousa received a Ph.D. in Pharmacology from Ohio State
                University, completed his post-doctoral research in
                Cardiovascular Pharmacology at the University of Kentucky in
                Lexington, and earned his MBA from Widener University in
                Chester, Pennsylvania. He is a Fellow of the American College of
                Cardiology (FACC), a Fellow of the National Academy of Clinical
                Biochemistry (FACB), a Fellow of the American Heart Association
                (FAHA), and a Fellow of the National Academy of Inventors
                (FNAI). He is a member of several national and international
                societies and serves on several study sections of NIH, DOD, and
                other funding agencies. In addition, he is an editor-in-chief
                and serves on the editorial boards and as a reviewer for several
                high impact scientific and medical journals.
              </p>
            </>
          }
        />

        <FormatBoardMember
          name="Paul J. Davis, MD, MACP"
          title="Founder, Executive Vice President and Chief Scientific Officer"
          bio={
            <>
              <p>
                Paul J. Davis, MD, MACP is Professor of Medicine, Albany Medical
                College, Albany, NY and Adjunct Professor of Pharmacy, Albany
                College of Pharmacy and Health Sciences. He is also Executive
                Vice President and Chief Scientific Officer at
                NanoPharmaceuticals LLC. He obtained his MD degree at Harvard
                Medical School, had his medical residency training at Albert
                Einstein College of Medicine in New York City and his endocrine
                training at the NIH. He has served on the medical faculties of
                Johns Hopkins University, the University at Buffalo and Albany
                Medical College. He was Department of Medicine Chair at Albany
                for ten years. He has been a member of 12 U.S. Department of
                Veterans Affairs research and clinical committees in Washington,
                DC, has served on multiple NIH committees and was a member for
                six years of the Extramural Review Panel of the Chernobyl Tissue
                Bank of the European Union. He has co-authored 300 research
                publications—largely dealing with the molecular bases of the
                actions of thyroid hormone—and has co-edited 4 scientific
                textbooks. He holds and is co-inventor of over 40 patents. He
                has served as Editor of two scientific journals, is currently on
                the editorial boards of five journals and is a subspecialty
                Editor of Faculty Opinions: Diabetes &#38; Endocrinology
                (formerly, Faculty of 1000). He has served in nationally elected
                offices of the American Board of Internal Medicine, the American
                College of Physicians, and the American Thyroid Association.
                With Dr. Shaker A. Mousa, he co-discovered the cell surface
                receptor for thyroid hormone analogues and co-founded
                NanoPharmaceuticals LLC.
              </p>
            </>
          }
        />

        <FormatBoardMember
          name="Michael Rosenthal was a founder and former CEO of NanoPharmaceuticals. His leadership and contribution to the Company will forever be remembered and appreciated."
          title=""
          bio={<></>}
        />
      </div>
    </Layout>
  );
};

export default BoardPage;
